import { auth } from '@/middleware';

const PaysView = () => import('@/views/PaysView.vue');
const PaysSuccessView = () => import('@/views/PaysSuccessView.vue');
const PaymentStatus = () => import('@/views/paymentStatus/PaymentStatus.vue');
const AppPayLink = () => import('@/views/payLink/AppPayLink.vue');
const AppPayBnlp = () => import('@/views/AppPayBnlp/AppPayBnlp.vue');
const AppPayQr = () => import('@/views/payQr/AppPayQr.vue');
const AppReference = () => import('@/views/AppReference.vue');
const AppPayInterOperableQr = () =>
	import('@/views/payInterOperableQR/AppPayInterOperableQr.vue');

const routes = [
	{
		path: '/pay',
		name: 'PaysViewPayments',
		component: PaysView,
		meta: { middleware: [auth] },
		props: {
			paymentInfo: {
				title: 'Cobrar',
				resume: 'Últimos Pagos',
				description: 'Elije el medio de cobro',
				moduleId: 2,
				buttonLabel: 'Cobrar',
				tooltipDescription:
					'Cobra a tus clientes a través de diferentes medios de pago.',
				amountIputLabel: 'Ingrese el valor a cobrar',
				optionsMenuResponsive: ['Cobrar', 'Resumen de pago']
			}
		}
	},
	{
		path: '/charge',
		name: 'PaysViewCharge',
		component: PaysView,
		meta: { middleware: [auth] },
		props: {
			paymentInfo: {
				title: 'Cargar',
				resume: 'Últimas Cargas',
				description: 'Elije el medio de carga',
				moduleId: 5,
				buttonLabel: 'Cargar',
				tooltipDescription:
					'Carga saldo a tu plataforma refacil a través de diferentes medios de pago.',
				amountIputLabel: 'Ingrese el valor a cargar',
				optionsMenuResponsive: ['Cargar', 'Resumen de cargas']
			}
		},
		children: [
			{
				path: ':origin',
				component: PaysView,
				meta: { middleware: [auth] },
				props: {
					paymentInfo: {
						title: 'Cargar',
						resume: 'Ultimas Cargas',
						description: 'Elije el medio de carga',
						moduleId: 5
					}
				}
			}
		]
	},
	{
		path: '/payment-success-view',
		name: 'paymentSuccessView',
		component: PaysSuccessView,
		props: route => ({ ...route.params }),
		meta: {
			title: 'Resumen de Pago'
		}
	},
	{
		path: '/resumen/:brandId?/:payReference?',
		name: 'paymentStatus',
		component: PaymentStatus,
		meta: {
			layout: 'app',
			title: 'Resumen de Pago',
			logo: 'logo.png'
		}
	},
	{
		path: '/payLink/:brandId/:reference',
		name: 'paymentLink',
		component: AppPayLink,
		meta: {
			layout: 'app',
			title: 'Resumen de Pago',
			logo: 'logo.png'
		}
	},
	{
		path: '/AppPayBnpl/',
		name: 'AppPayBnpl',
		component: AppPayBnlp,
		meta: {
			layout: 'app',
			title: 'Bancolombia BNPL',
			logo: 'logo.png'
		}
	},
	{
		path: '/payQr/:brandId/:reference',
		name: 'paymentQr',
		component: AppPayQr,
		meta: {
			layout: 'app',
			title: 'Resumen de Pago',
			logo: 'logo.png'
		}
	},
	{
		path: '/reference/:id',
		name: 'reference',
		component: AppReference,
	},
	{
		path: '/payInterOperableQr/:brandId/:reference',
		name: 'payInterOperableQr',
		component: AppPayInterOperableQr,
	},
];

export default routes;
