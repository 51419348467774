<template>
	<div
		id="mf-pays-app"
		class="mf-app-container"
		:class="isSpecialRoute ? 'noAuth' : ''"
	>
		<router-view />
	</div>
</template>

<script>
import { specialRoutes } from '@superpagos/mf-services';
// Version
import { brandReducer } from '@superpagos/mf-store';
import version from './version';

export default {
	name: 'App',
	data() {
		return {
			isSpecialRoute: false
		};
	},
	mounted() {
		brandReducer.setGlobalVersion({ app: 'pays', version });
		this.decideAction(this.$route.path);
	},
	methods: {
		decideAction(path) {
			this.isSpecialRoute = specialRoutes.some(e => path.includes(e));
		}
	},

	watch: {
		'$route.path'(value) {
			this.decideAction(value);
		}
	}
};
</script>

<style lang="scss" scoped>
.noAuth {
	margin: 0 !important;
	top: 0;
	padding-bottom: 0;
	width: 100%;
	height: 100%;
	background: #f8fafc;
	border-top: 0px;
	overflow: none !important;
}

@media (max-width: 768px) {
	.noAuth {
		overflow: scroll !important;
	}

	.noAuth::-webkit-scrollbar {
		display: none;
	}
}
</style>
