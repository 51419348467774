/**
 * Auth
 * @param Store
 * @param next
 * @returns {*}
 */
const auth = ({ Store, next }) => {
	if (!Store.getters.user) {
		window.location = '/login';
		window.clearInterval(window.threadRealTime);
		return;
	}

	return next();
}

/**
 * VueRouter next factory
 * Creates a `nextMiddleware()` function which not only
 * runs the default `next()` callback but also triggers
 * the subsequent Middleware function.
 * @param context
 * @param middleware
 * @param index
 * @returns {(function(...[*]): void)|*}
 */
const nextFactory = (context, middleware, index) => {
	const subsequentMiddleware = middleware[index];
	// If no subsequent Middleware exists,
	// the default `next()` callback is returned.
	if (!subsequentMiddleware) return context.next;

	return (...parameters) => {
		// Run the default Vue Router `next()` callback first.
		context.next(...parameters);
		// Then run the subsequent Middleware with a new
		// `nextMiddleware()` callback.
		const nextMiddleware = nextFactory(context, middleware, index + 1);
		subsequentMiddleware({ ...context, next: nextMiddleware });
	};
}

export { auth, nextFactory }