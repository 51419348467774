import Vue from 'vue';
import Router from 'vue-router';
import Store from '@superpagos/mf-store';
import {nextFactory} from "@/middleware";
import routes from "@/router/routes";

Vue.use(Router);

const router = new Router({
	mode: 'history',
	base: '/refacilpay',
	routes
});

router.beforeEach((to, from, next) => {
	if (to.meta.middleware) {
		const middleware = Array.isArray(to.meta.middleware)
			? to.meta.middleware
			: [to.meta.middleware];

		const context = {
			Store,
			from,
			next,
			router,
			to
		};
		const nextMiddleware = nextFactory(context, middleware, 1);
		return middleware[0]({ ...context, next: nextMiddleware });
	}

	return next();
});

export default router;
