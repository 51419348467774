import './set-public-path';
import Vue from 'vue';
import Vuex from 'vuex';
import singleSpaVue from 'single-spa-vue';

import App from './App.vue';
import services, { MixinService } from '@superpagos/mf-services';
import store from '@superpagos/mf-store';
import router from './router';
import BootstrapVue from 'bootstrap-vue';
import {
	AppButton,
	AppInput,
	AppLoader,
	AppModal,
	AppSelect,
} from '@superpagos/mf-custom-components';

services.ApiService.init(store);
services.ApiServiceRefacilOld.init(store);
services.ApiServicePay.init(store);
services.ApiApagarService.init(store);
services.ApiServiceRequirement.init(store);

Vue.config.productionTip = false;

Vue.component('app-input', AppInput);
Vue.component('app-select', AppSelect);
Vue.component('app-loader', AppLoader);
Vue.component('app-modal', AppModal);
Vue.component('app-button', AppButton);

Vue.use(Vuex);
Vue.use(BootstrapVue);

Vue.mixin({ methods: MixinService });

var Store = store;

Vue.directive('can', {
	bind(el, binding) {
		var user = Store.state.auth.user;
		if (user.Rol.permissions.indexOf(binding.value) === -1) {
			el.style.display = 'none';
		}
	},
});

const vueLifecycles = singleSpaVue({
	Vue,
	appOptions: {
		store,
		render: h => h(App),
		router,
	},
});
export { default as version } from './version';
export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
